import React, { useMemo, useEffect } from "react";
import { MessagesContainer } from "./styled";
import { useParams } from "react-router-dom";
import { useCompletionsHistory, useMe } from "@mightybot/core";
import Chat from "../Chat/Chat";
import { useStreaming } from "../../contexts/StreamingContext";

const ChatsMessages: React.FC = () => {
	const { data: author } = useMe();
	const { chatId: activeConversationId } = useParams();
	const { completionHistory: chats } =
		useCompletionsHistory(activeConversationId);
	const { streamingMessage, isStreaming, currentStreamingQuestion } =
		useStreaming();

	useEffect(() => {
		if (chats.length > 0) {
			window.requestAnimationFrame(() => {
				const container = document.getElementById("chats-content-container");
				if (container) {
					const scrollHeight = container.scrollHeight;
					const height = container.clientHeight;
					const maxScrollTop = scrollHeight - height;
					container.scrollTo({
						top: maxScrollTop > 0 ? maxScrollTop : 0,
						behavior: "smooth",
					});
				}
			});
		}
	}, [chats, activeConversationId]);

	const messages = useMemo(() => {
		const lastMessage = chats[chats.length - 1];
		const isStreamingMessageInHistory =
			lastMessage &&
			lastMessage.user_question === currentStreamingQuestion &&
			lastMessage.response === streamingMessage;

		const regularChats = chats.map((chat) => (
			<Chat
				key={chat.created_at}
				timestamp={chat.created_at}
				question={chat.user_question}
				reply={chat.response}
				author={author}
			/>
		));

		if (!isStreaming || isStreamingMessageInHistory) return regularChats;

		return [
			...regularChats,
			<Chat
				key="streaming-message"
				timestamp={new Date().toISOString()}
				question={currentStreamingQuestion}
				reply={streamingMessage}
				author={author}
				isStreaming={true}
			/>,
		];
	}, [chats, isStreaming, currentStreamingQuestion, streamingMessage, author]);

	return (
		<MessagesContainer newchat={!activeConversationId ? "true" : "false"}>
			{messages}
		</MessagesContainer>
	);
};

export default ChatsMessages;
