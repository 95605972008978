import styled, { keyframes } from "styled-components";
import { Flex, Text } from "@mightybot/web-ui";

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const slideUp = keyframes`
	from {
		transform: translateY(100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
`;

export const HeaderTitle = styled(Text)`
	color: var(--mb-color-light-gray-11);
	font-size: 36px;
	font-weight: 500;
	line-height: 44px;
	margin: 0;
	align-self: stretch;
	text-align: center;
`;

export const HeaderSubtitle = styled(Text)`
	color: var(--mb-color-light-gray-11);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	max-width: 685px;
	margin: 0;
	text-align: center;
	align-self: center;
`;

export const ContactLink = styled(Text)`
	color: var(--mb-color-light-blue-8);
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

export const ContentWrapper = styled(Flex)`
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	/* Center and constrain PlanTiles */
	> div {
		max-width: 800px;
		margin: 0 auto;
		width: 100%;
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none;
	scrollbar-width: none;
`;

export const OverlayContainer = styled(Flex)`
	position: absolute;
	padding-top: calc(100% - 50vh);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.8) 40%
	);
	z-index: 1000;
	flex-direction: column;
	justify-content: flex-end;
	align-items: stretch;
	animation: ${fadeIn} 0.3s ease-out forwards;
`;

export const OverlayContent = styled(Flex)`
	background: white;
	border-radius: 24px 24px 0 0;
	width: 100%;
	min-height: 100vh;
	padding: 24px;
	position: relative;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
	animation: ${slideUp} 0.4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
	transform-origin: bottom;

	&::before {
		content: "";
		position: absolute;
		top: -50px;
		left: 0;
		right: 0;
		height: 50px;
		background: linear-gradient(
			to top,
			rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0)
		);
	}
`;

export const StyledContentWrapper = styled(ContentWrapper)`
	padding: 0;
	margin: 0;

	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
`;

export const Footer = styled(Flex)`
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding-bottom: 8px;
	flex-shrink: 0;
	margin: 0;
`;
