import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
	NavBarContainer,
	NavBarLogo,
	NavigationMenu,
	NavigationMenuLink,
	NavigationMenuItem,
	IconBackground,
	UserPopoverContainer,
	MobileNavBar,
	MobileNavBarContent,
	MobileNavBarOverlay,
	ProfileFlyout,
	NavItemLabel,
} from "./styled";
import { MightyBotLogo } from "@mightybot/core-ui";
import {
	AvatarWrapper,
	Icon,
	Flex,
	Tooltip,
	Popover,
	Button,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import { useMe } from "@mightybot/core";
import Profile from "./Profile";
import { internalNavLinks, navLinks } from "./NavData";
import ContactUsOverlay from "../ContactUsOverlay/ContactUsOverlay";

export interface NavLinkItem {
	to: string;
	Icon: React.ComponentType<any>;
	label: string;
	type?: "internal" | "external";
	className?: string;
}

interface UserPopoverProps {
	isPopoverOpen: boolean;
	setIsPopoverOpen: (open: boolean) => void;
	enabled?: boolean;
}

interface RenderNavLinksProps {
	links: NavLinkItem[];
	setIsMobileMenuOpen?: (open: boolean) => void;
}

const RenderNavLinks = ({
	links,
	setIsMobileMenuOpen,
}: RenderNavLinksProps) => {
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const isActive = (to: string) => window.location.pathname === to;

	const handleNavLinkClick = (to: string) => {
		if (isMobile) {
			setIsMobileMenuOpen?.(false);
		}
	};

	return (
		<Flex direction="column" gap={isMobile ? "0px" : "16px"}>
			{links.map(({ to, Icon, label, type, className }) => (
				<NavigationMenuItem key={to}>
					{type === "internal" ? (
						<NavLink
							to={to}
							aria-label={label}
							style={{ textDecoration: "none" }}
							className={className}
							onClick={() => handleNavLinkClick(to)}
						>
							{({ isActive }) => (
								<NavigationMenuLink
									active={isActive.toString()}
									direction="column"
									justify="center"
									align="center"
								>
									<IconBackground
										active={isActive.toString()}
										justify="center"
										align="center"
									>
										<Icon
											size={20}
											fill={isMobile ? "var(--gray-12)" : "var(--gray-1)"}
											weight={isActive ? "fill" : "regular"}
										/>
									</IconBackground>
									{label !== "Settings" && <NavItemLabel>{label}</NavItemLabel>}
								</NavigationMenuLink>
							)}
						</NavLink>
					) : (
						<a
							href={to}
							aria-label={label}
							target="_blank"
							rel="noopener noreferrer"
							onClick={() => (isMobile ? setIsMobileMenuOpen?.(false) : null)}
							style={{ textDecoration: "none" }}
						>
							<NavigationMenuLink
								active={isActive(to).toString()}
								direction="column"
								justify="center"
								align="center"
							>
								<Tooltip content={label}>
									<IconBackground
										active={isActive(to).toString()}
										justify="center"
										align="center"
									>
										<Icon
											size={20}
											fill={isMobile ? "var(--gray-12)" : "var(--gray-1)"}
										/>
									</IconBackground>
								</Tooltip>
							</NavigationMenuLink>
						</a>
					)}
				</NavigationMenuItem>
			))}
		</Flex>
	);
};

const UserPopover = ({
	isPopoverOpen,
	setIsPopoverOpen,
	enabled = true,
}: UserPopoverProps) => {
	const { data: user } = useMe();
	return (
		<UserPopoverContainer>
			<Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
				<Popover.Trigger disabled={!enabled}>
					<Button
						variant="ghost"
						style={{
							background: "none",
							border: "none",
							cursor: "pointer",
						}}
					>
						<AvatarWrapper
							alt={user?.name ?? "User"}
							src={user?.profile_pic ?? ""}
							radius="full"
							variant="solid"
							size="2"
						/>
					</Button>
				</Popover.Trigger>
				<Popover.Content style={{ padding: 0 }}>
					<ProfileFlyout>
						<Profile setIsPopoverOpen={setIsPopoverOpen} />
					</ProfileFlyout>
				</Popover.Content>
			</Popover.Root>
		</UserPopoverContainer>
	);
};

const NavBar: React.FC = () => {
	const { data: user } = useMe();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const enabledNavLinks = navLinks.filter(
		(link) => user?.is_admin || link.label !== "Admin",
	);

	const toggleMobileMenu = () => {
		if (isMobileMenuOpen) {
			setIsClosing(true);
			setTimeout(() => {
				setIsMobileMenuOpen(false);
				setIsClosing(false);
			}, 300); // Match this with the transition duration in styled.ts
		} else {
			setIsMobileMenuOpen(true);
		}
	};

	useEffect(() => {
		if (isMobileMenuOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}

		return () => {
			document.body.style.overflow = "unset";
		};
	}, [isMobileMenuOpen]);

	if (isMobile) {
		return (
			<React.Fragment>
				<MobileNavBar>
					<Flex align="center" gap="12px" style={{ width: "100%" }}>
						<Button
							variant="ghost"
							onClick={toggleMobileMenu}
							style={{ cursor: "pointer" }}
						>
							<Icon.List size={24} fill="var(--gray-1)" />
						</Button>
						<NavBarLogo
							src={MightyBotLogo}
							alt="MightyBot Logo"
							style={{ marginBottom: "0px" }}
						/>
					</Flex>
					<UserPopover
						isPopoverOpen={isPopoverOpen}
						setIsPopoverOpen={setIsPopoverOpen}
					/>
				</MobileNavBar>
				<MobileNavBarOverlay
					onClick={toggleMobileMenu}
					className={isMobileMenuOpen ? "open" : ""}
				>
					<MobileNavBarContent
						onClick={(e: React.MouseEvent) => e.stopPropagation()}
						className={isMobileMenuOpen ? (isClosing ? "closing" : "open") : ""}
					>
						<Icon.List
							size={24}
							fill="var(--gray-12)"
							onClick={toggleMobileMenu}
							style={{ cursor: "pointer", marginBottom: "10px" }}
						/>
						{/* <Icon.X
							size={20}
							onClick={toggleMobileMenu}
							style={{ cursor: "pointer", alignSelf: "flex-end" }}
						/> */}
						<NavigationMenu>
							<RenderNavLinks
								links={enabledNavLinks}
								setIsMobileMenuOpen={setIsMobileMenuOpen}
							/>
							<Flex
								direction="column"
								style={{ marginTop: "auto", width: "100%" }}
							>
								<Profile
									profileOptions={internalNavLinks.filter(
										(link) => link.label !== "Install",
									)}
								/>
							</Flex>
							<ContactUsOverlay />
						</NavigationMenu>
					</MobileNavBarContent>
				</MobileNavBarOverlay>
			</React.Fragment>
		);
	}

	return (
		<NavBarContainer>
			<NavBarLogo src={MightyBotLogo} alt="MightyBot Logo" />
			<NavigationMenu style={{ gap: "16px" }}>
				<RenderNavLinks links={enabledNavLinks} />
			</NavigationMenu>
			<Flex direction="column" align="center" style={{ marginTop: "auto" }}>
				<NavigationMenu style={{ gap: "10px", marginBottom: "10px" }}>
					<ContactUsOverlay />
					<RenderNavLinks links={internalNavLinks} />
				</NavigationMenu>
				<UserPopover
					isPopoverOpen={isPopoverOpen}
					setIsPopoverOpen={setIsPopoverOpen}
				/>
			</Flex>
		</NavBarContainer>
	);
};

export default NavBar;
