import React, { useState, useEffect } from "react";
import {
	SidebarContainer,
	NavigationMenu,
	NavigationMenuList,
	NavigationMenuItem,
	NavigationMenuLink,
	IconCaretRight,
} from "./styled";
import {
	Flex,
	Icon,
	Text,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import { useLocation } from "react-router-dom";
import { useMe } from "@mightybot/core";

interface DropdownItem {
	to: string;
	label: string;
}

interface NavItem {
	to: string;
	label: string;
	icon: React.ReactNode;
	order: number;
	isDropdown?: boolean;
	dropdownItems?: DropdownItem[];
	visibilityCheck: (flags: FeatureFlags) => boolean;
}

interface FeatureFlags {
	isAdmin: boolean;
	showMapleBilling: boolean;
}

const navItems: NavItem[] = [
	{
		to: "/settings/general",
		label: "General",
		icon: <Icon.Gear size={20} />,
		order: 1,
		visibilityCheck: () => true,
	},
	{
		to: "/settings/personalization",
		label: "Personalization",
		icon: <Icon.Sparkle size={20} />,
		order: 2,
		visibilityCheck: () => false,
	},
	{
		to: "/settings/user-notifications",
		label: "User Notifications",
		icon: <Icon.BellRinging size={20} />,
		order: 3,
		visibilityCheck: () => true,
	},
	{
		to: "/settings/connect",
		label: "Connect with Apps",
		icon: <Icon.SquaresFour />,
		order: 4,
		visibilityCheck: () => true,
	},
	{
		to: "/settings/outbox",
		label: "Outbox",
		icon: <Icon.Tray />,
		order: 5,
		visibilityCheck: () => false,
	},
	{
		to: "/settings/privacy",
		label: "Privacy",
		icon: <Icon.LockKey size={20} />,
		order: 6,
		isDropdown: true,
		dropdownItems: [
			// {
			// 	to: "/settings/privacy/contacts",
			// 	label: "Contacts",
			// },
			// {
			// 	to: "/settings/privacy/slack-channels",
			// 	label: "Slack Channels",
			// },
			// {
			// 	to: "/settings/privacy/google-drive",
			// 	label: "Google Drive",
			// },
			{
				to: "/settings/privacy/data-collection",
				label: "Data Collection",
			},
		],
		visibilityCheck: () => true,
	},
	{
		to: "/admin/enterprises",
		label: "Admin",
		icon: <Icon.Shield />,
		order: 7,
		visibilityCheck: ({ isAdmin }) => isAdmin,
	},
	{
		to: "/settings/meetings",
		label: "Meetings & Summaries",
		icon: <Icon.VideoCamera size={20} />,
		order: 8,
		visibilityCheck: () => true,
	},
	{
		to: "/settings/teams",
		label: "Team",
		icon: <Icon.Users size={20} />,
		order: 9,
		visibilityCheck: () => true,
	},
	{
		to: "/settings/billing",
		label: "Billing",
		icon: <Icon.Receipt size={20} />,
		order: 10,
		visibilityCheck: ({ showMapleBilling }) => showMapleBilling,
	},
	{
		to: "/settings/apps-configuration",
		label: "Apps Configuration",
		icon: <Icon.SquaresFour size={20} />,
		order: 11,
		visibilityCheck: ({ isAdmin }) => isAdmin,
	},
];

interface SidebarProps {
	onSelect?: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onSelect }) => {
	const { data: user } = useMe();
	const location = useLocation();
	const { pathname } = location;

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const featureFlags: FeatureFlags = {
		isAdmin: user?.is_admin ?? false,
		showMapleBilling: user?.role === "OWNER",
	};

	const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
		{},
	);

	const isActive = (path: string) => {
		// Remove the "/settings" prefix from both paths for comparison
		const normalizedPath = path.replace(/^\/settings/, "");
		const normalizedPathname = pathname.replace(/^\/settings/, "");
		return normalizedPathname === normalizedPath;
	};

	useEffect(() => {
		// Auto-open dropdown if we're on a sub-route
		const normalizedPathname = pathname.replace(/^\/settings\//, "");
		const parentPath = normalizedPathname.split("/")[0];

		setOpenDropdowns((prev) => ({
			...prev,
			[`/settings/${parentPath}`]: normalizedPathname.includes("/"),
		}));
	}, [pathname]);

	const toggleDropdown = (path: string) => {
		setOpenDropdowns((prev) => ({
			...prev,
			[path]: !prev[path],
		}));
	};

	const enabledNavItems = navItems
		.filter((item) => item.visibilityCheck(featureFlags))
		.sort((a, b) => a.order - b.order);

	const handleItemClick = (e: React.MouseEvent, item: NavItem) => {
		if (item.isDropdown) {
			e.preventDefault();
			toggleDropdown(item.to);
		} else if (isMobile && onSelect) {
			onSelect();
		}
	};

	return (
		<SidebarContainer>
			<NavigationMenu>
				<NavigationMenuList>
					{enabledNavItems.map((item) => (
						<NavigationMenuItem key={item.to}>
							<NavigationMenuLink
								to={item.to}
								active={`${isActive(item.to)}`}
								onClick={(e) => handleItemClick(e, item)}
							>
								{item.icon}
								<Flex
									justify="between"
									align="center"
									style={{ width: "100%" }}
								>
									<Text weight="medium">{item.label}</Text>
									{item.isDropdown && (
										<IconCaretRight
											size={14}
											style={{
												transform: openDropdowns[item.to]
													? "rotate(90deg)"
													: "rotate(0deg)",
												transition: "transform 0.3s ease",
											}}
										/>
									)}
								</Flex>
							</NavigationMenuLink>

							{item.isDropdown && openDropdowns[item.to] && (
								<NavigationMenuList>
									{item.dropdownItems?.map((dropdownItem) => (
										<NavigationMenuItem
											key={dropdownItem.to}
											style={{
												borderLeft: "1px solid var(--mb-gray-4)",
												marginLeft: "24px",
											}}
										>
											<NavigationMenuLink
												to={dropdownItem.to}
												active={`${isActive(dropdownItem.to)}`}
												style={{ paddingLeft: "15px" }}
												onClick={() => {
													if (isMobile && onSelect) {
														onSelect();
													}
												}}
											>
												<Text>{dropdownItem.label}</Text>
											</NavigationMenuLink>
										</NavigationMenuItem>
									))}
								</NavigationMenuList>
							)}
						</NavigationMenuItem>
					))}
				</NavigationMenuList>
			</NavigationMenu>
		</SidebarContainer>
	);
};

export default Sidebar;
