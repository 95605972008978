import React from "react";
import { Flex, Icon, Text, Button, Skeleton } from "@mightybot/web-ui";
import { useNavigate, useParams } from "react-router-dom";
import TruncatedMarkdown from "../TruncatedMarkdown";
import { MeetingContentItem, useMeetingContent } from "@mightybot/core";
import {
	MeetingContentPreviewContainer,
	MeetingContentPreviewItem,
	MeetingContentPreviewOutline,
	FocusableContent,
} from "./styled";

type MeetingContentItemWithParagraph = MeetingContentItem & {
	paragraph: string;
};

interface MeetingContentPreviewProps {}

const MeetingContentPreview: React.FC<MeetingContentPreviewProps> = ({}) => {
	const navigate = useNavigate();
	const { meetingId } = useParams();
	const { meetingContent, isLoading } = useMeetingContent(meetingId ?? "");

	const isMeetingContentPresent = meetingContent && meetingContent.length > 0;

	const handleMoreDetails = () => {
		navigate(`/meetings/${meetingId}/details`);
	};

	if (!meetingId) {
		return (
			<MeetingContentPreviewContainer
				direction="column"
				justify="center"
				style={{ height: "100%" }}
			>
				<Text
					size="5"
					weight="medium"
					style={{
						marginBottom: "20px",
						borderBottom: "1px solid var(--mb-gray-5)",
						paddingBottom: "20px",
					}}
				>
					Select a meeting to view its content
				</Text>
				<Icon.FileSearch
					size={64}
					weight="light"
					style={{ margin: "20px 0", color: "var(--mb-gray-8)" }}
				/>
				<Text
					size="3"
					style={{
						color: "var(--mb-gray-8)",
					}}
				>
					Meeting content will appear here once you choose a meeting from the
					list.
				</Text>
			</MeetingContentPreviewContainer>
		);
	}

	if (isLoading) {
		return (
			<MeetingContentPreviewContainer direction="column">
				<Skeleton
					width="150px"
					height="24px"
					style={{ marginBottom: "16px" }}
				/>
				<MeetingContentPreviewOutline>
					{[1, 2, 3].map((item) => (
						<MeetingContentPreviewItem key={item}>
							<Flex
								align="center"
								justify="between"
								style={{ width: "100%", marginBottom: "10px" }}
							>
								<Flex align="center" gap="8px">
									<Skeleton width="20px" height="20px" />
									<Skeleton width="150px" height="24px" />
								</Flex>
								<Skeleton width="100px" height="32px" />
							</Flex>
							<Skeleton width="100%" height="60px" />
						</MeetingContentPreviewItem>
					))}
				</MeetingContentPreviewOutline>
			</MeetingContentPreviewContainer>
		);
	}

	return (
		<MeetingContentPreviewContainer direction="column">
			<Text size="2">
				{isMeetingContentPresent
					? "Meeting Outline"
					: "No content summary available"}
			</Text>
			{isMeetingContentPresent && (
				<MeetingContentPreviewOutline>
					{meetingContent.map((item) => (
						<MeetingContentPreviewItem key={item.id}>
							<Flex align="center" justify="between" style={{ width: "100%" }}>
								<Flex align="center" gap="8px">
									<Icon.FileText
										size={20}
										weight="bold"
										style={{ color: "var(--mb-color-gray-8)" }}
									/>
									<FocusableContent data-content={item.title}>
										<Text weight="medium" size="4">
											{item.title}
										</Text>
									</FocusableContent>
									<Icon.CaretRight
										size={16}
										weight="light"
										style={{ color: "var(--mb-color-gray-6)" }}
									/>
								</Flex>
								<Button
									variant="soft"
									size="2"
									style={{
										cursor: "pointer",
										color: "var(--mb-color-light-blue-8)",
										borderRadius: "5px",
										fontWeight: "normal",
									}}
									onClick={handleMoreDetails}
								>
									More Details
								</Button>
							</Flex>

							<FocusableContent data-content={item.content}>
								<TruncatedMarkdown
									content={item.content}
									isPreview
									singleLine
								/>
							</FocusableContent>
						</MeetingContentPreviewItem>
					))}
				</MeetingContentPreviewOutline>
			)}
			{isMeetingContentPresent && (
				<Flex justify="end" style={{ marginTop: "20px" }}>
					<Icon.ThumbsUp
						size={24}
						weight="light"
						style={{
							cursor: "pointer",
							marginRight: "15px",
							color: "var(--mb-color-gray-7)",
						}}
					/>
					<Icon.ThumbsDown
						size={24}
						weight="light"
						style={{ cursor: "pointer", color: "var(--mb-color-gray-7)" }}
					/>
				</Flex>
			)}
		</MeetingContentPreviewContainer>
	);
};

export default MeetingContentPreview;
