import React from "react";
import Layout from "../components/Layout";
import Home from "../components/Home";

export default function HomeRoute() {
	return (
		<Layout>
			<Home />
		</Layout>
	);
}
