import { Icon } from "@mightybot/web-ui";
import { NavLinkItem } from "./NavBar";

export const navLinks: NavLinkItem[] = [
	{ to: "/home", Icon: Icon.House, label: "Home", type: "internal" },
	{
		to: "/chats",
		Icon: Icon.ChatTeardropDots,
		label: "Chats",
		type: "internal",
		className: "nav-chats-button",
	},
	{
		to: "/search",
		Icon: Icon.ListMagnifyingGlass,
		label: "Search",
		type: "internal",
		className: "nav-search-button",
	},
	{
		to: "/meetings",
		Icon: Icon.Video,
		label: "Meetings",
		type: "internal",
		className: "nav-meetings-button",
	},
	{
		to: "/people",
		Icon: Icon.AddressBook,
		label: "People",
		type: "internal",
		className: "nav-people-button",
	},
	{
		to: "/companies",
		Icon: Icon.Buildings,
		label: "Companies",
		type: "internal",
		className: "nav-companies-button",
	},
	{
		to: "/deals",
		Icon: Icon.Briefcase,
		label: "Deals",
		type: "internal",
		className: "nav-deals-button",
	},
];

export const internalNavLinks: NavLinkItem[] = [
	{
		to: `https://chromewebstore.google.com/detail/mightybot-beta/${process.env.REACT_APP_PUBLIC_EXTENSION_ID}`,
		Icon: Icon.PuzzlePiece,
		label: "Install",
		type: "external",
	},
	{
		to: "/settings/general",
		Icon: Icon.Gear,
		label: "Settings",
		type: "internal",
		className: "nav-settings-button",
	},
	{
		to: "https://www.mightybot.ai/help/installation",
		Icon: Icon.Question,
		label: "Help",
		type: "external",
	},
];
