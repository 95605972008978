import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PosthogUtil, useLogin, useMe } from "@mightybot/core";
import {
	GoogleIcon,
	MBLogo,
	OnboardingAnimationFallback,
} from "@mightybot/core-ui";
import {
	Box,
	Callout,
	Flex,
	Heading,
	Icon,
	Spinner,
	Text,
} from "@mightybot/web-ui";

import * as Styled from "./styled";
import Disclaimer from "../Disclaimer";
import Loading from "../Loading";
import Lottie from "react-lottie-player";
import { ErrorBoundary } from "react-error-boundary";

import OnboardingAnimation from "../Onboarding/OnboardingAnimation.json";

export const loginViewLoader = () => {
	return <div>Loading login...</div>;
};

declare global {
	interface Window {
		google: any;
	}
}

// Implement this function to generate a unique nonce
const generateNonce = () => {
	return (
		Math.random().toString(36).substring(2, 15) +
		Math.random().toString(36).substring(2, 15)
	);
};

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const Auth = () => {
	const [signInStarted, setSignInStarted] = useState<boolean>(false);
	const [login, { isError }] = useLogin();
	const { data: user, isLoading: isUserLoading } = useMe();
	const navigate = useNavigate();
	const [isTokenError, setIsTokenError] = useState(false);
	const [googleSignInReady, setGoogleSignInReady] = useState(false);
	const [googleError, setGoogleError] = useState<string | null>(null);
	const location = useLocation();
	const isSignupFlow = location.pathname.includes("/signup");

	const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
		null,
	);

	const workspaceDomain = window.location.hostname;
	useEffect(() => {
		if (user) {
			setSignInStarted(false);
			const intendedDestination = localStorage.getItem("intendedDestination");
			if (intendedDestination) {
				localStorage.removeItem("intendedDestination");
				navigate(intendedDestination);
			} else {
				const isSignupFlow = localStorage.getItem("isSignupFlow");
				localStorage.removeItem("isSignupFlow");
				const navigateTo = isSignupFlow ? "/onboarding" : "/home";
				navigate(navigateTo);
			}
		}
	}, [user, navigate]);

	useEffect(() => {
		const message = localStorage.getItem("loginErrorMessage");
		if (message) {
			setLoginErrorMessage(message);
			localStorage.removeItem("loginErrorMessage");
		}
	}, []);

	const initializeGoogleSignIn = useCallback(() => {
		if (window.google && GOOGLE_CLIENT_ID && !isUserLoading && !user) {
			try {
				window.google.accounts.id.initialize({
					client_id: GOOGLE_CLIENT_ID,
					callback: handleCredentialResponse,
					auto_select: false,
					cancel_on_tap_outside: false,
					context: "signin",
					ux_mode: "popup",
					nonce: generateNonce(),
					//TODO: Need to enable fedcm to ensure continuity. currently disabled to ensure that we can login with google
					use_fedcm_for_prompt: true,
				});
				setGoogleSignInReady(true);
				setGoogleError(null);

				// Prompt for One Tap
				if (
					["/app/login", "/app/signin", "/app/auth", "/app/signup"].includes(
						window.location.pathname,
					)
				) {
					window.google.accounts.id.prompt((notification: any) => {
						if (notification.isSkippedMoment()) {
							console.debug("One Tap prompt was skipped");
						} else if (notification.isDismissedMoment()) {
							console.debug(
								"One Tap prompt was dismissed",
								notification.getDismissedReason(),
							);
							if (notification.getDismissedReason() === "credential_returned") {
								console.debug("User signed in successfully");
							} else if (notification.getDismissedReason() === "user_cancel") {
								console.debug("User cancelled the prompt");
							}
						}
					});
				}
			} catch (error) {
				console.error("Error initializing Google Sign-In:", error);
				setGoogleError("Failed to initialize Google Sign-In");
			}
		}
	}, [GOOGLE_CLIENT_ID, user, isUserLoading]);

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://accounts.google.com/gsi/client";
		script.onload = initializeGoogleSignIn;
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [initializeGoogleSignIn]);

	const handleCredentialResponse = async (response: any) => {
		if (response.credential) {
			setSignInStarted(true);
			try {
				const loginResponse = await login({
					token: response.credential,
					token_type: "google",
					workspace_domain: workspaceDomain,
				}).unwrap();
				PosthogUtil.captureEvent("User logged in", {
					tenant: workspaceDomain,
				});
				const isExistingUser = loginResponse.data.is_existing_user;
				const navigateTo =
					!isExistingUser && isSignupFlow ? "/onboarding" : "/home";
				navigate(navigateTo);
			} catch (error) {
				console.error("Login error:", error);
				setIsTokenError(true);
				setGoogleError(
					"Failed to login with Google. Falling back to traditional OAuth.",
				);
				fallbackToTraditionalOAuth();
			} finally {
				setSignInStarted(false);
			}
		} else {
			console.error("No credential received from Google");
			setGoogleError(
				"No credential received from Google. Falling back to traditional OAuth.",
			);
			fallbackToTraditionalOAuth();
		}
	};

	const onSignInClick = useCallback(() => {
		if (isSignupFlow) {
			localStorage.setItem("isSignupFlow", "true");
		} else {
			localStorage.removeItem("isSignupFlow");
		}

		setSignInStarted(true);
		fallbackToTraditionalOAuth();
	}, []);

	const fallbackToTraditionalOAuth = () => {
		const clientIdEncoded = encodeURIComponent(GOOGLE_CLIENT_ID as string);
		const redirectUri = encodeURIComponent(
			`${window.location.origin}/app/auth/callback`,
		);
		const scopes = encodeURIComponent(["openid", "email", "profile"].join(" "));
		const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientIdEncoded}&response_type=token&redirect_uri=${redirectUri}&scope=${scopes}&prompt=select_account`;
		window.location.href = authUrl;
	};

	if (isUserLoading || user) {
		return <Loading />;
	}

	return (
		<Styled.AuthContainer>
			<Styled.SplitLayout>
				<Styled.ContentSection>
					<Flex direction="column" align="center" justify="center">
						<img
							src={MBLogo}
							alt=""
							style={{ width: 192, height: 40, marginBottom: 30 }}
						/>
						<Heading
							as="h1"
							size="3"
							style={{
								marginBottom: isSignupFlow ? 20 : 20,
								fontWeight: 500,
								fontSize: isSignupFlow ? 24 : 18,
							}}
						>
							{isSignupFlow
								? "Start 14-day free trial"
								: "Get started with MightyBot"}
						</Heading>
						{isSignupFlow && (
							<Styled.Subtitle style={{ marginBottom: 30 }}>
								Signup and inside get a $50 gift card for talking to a founder,
								No credit card required.
							</Styled.Subtitle>
						)}
						{(isError || isTokenError) && (
							<Callout.Root color="red" style={{ marginBottom: "10px" }}>
								<Callout.Icon>
									<Icon.WarningCircle />
								</Callout.Icon>
								<Callout.Text>There was an error!</Callout.Text>
							</Callout.Root>
						)}
						<Styled.LogInButton
							disabled={signInStarted || !googleSignInReady}
							onClick={onSignInClick}
							id="google-sign-in-button"
						>
							{signInStarted ? (
								<Spinner weight="fill" size={22} />
							) : (
								<>
									<img src={GoogleIcon} alt="Google Icon" />
									<span>
										{isSignupFlow
											? "Continue with Google"
											: "Sign in with Google"}
									</span>
								</>
							)}
						</Styled.LogInButton>

						{(googleError || loginErrorMessage) && (
							<Text color="red" size="2" style={{ marginTop: "10px" }}>
								{googleError || loginErrorMessage}
							</Text>
						)}
						<Disclaimer />
					</Flex>
				</Styled.ContentSection>

				<Styled.AnimationSection>
					<ErrorBoundary
						fallback={
							<img
								src={OnboardingAnimationFallback}
								alt="Animation Fallback"
								style={{ width: "100%", height: "100%", objectFit: "cover" }}
							/>
						}
					>
						<Lottie
							animationData={OnboardingAnimation}
							loop={true}
							play={true}
							style={{ width: "100%", height: "100%", display: "block" }}
							rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
						/>
					</ErrorBoundary>
				</Styled.AnimationSection>
			</Styled.SplitLayout>
		</Styled.AuthContainer>
	);
};

export default Auth;
