import styled from "styled-components";
import { Flex, Text } from "@mightybot/web-ui";

export const Container = styled(Flex)`
	width: 100%;
	height: 100%;
	background: var(--mb-color-light-gray-1);
	border-radius: 12px;
	overflow: hidden;
	border: 1px solid var(--mb-color-light-gray-5);
	flex-direction: column;
`;

export const Header = styled(Flex)`
	padding: 24px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);
	align-items: center;
`;

export const HeaderTitle = styled(Text)`
	color: var(--mb-color-light-gray-11);
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
`;

export const Content = styled(Flex)`
	flex: 1;
	flex-direction: column;
	padding: 10px 20px 10px 20px;
`;

export const NavTabs = styled(Flex)`
	align-items: center;
	padding: 0 24px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);
	gap: 32px;
	background: var(--mb-color-light-gray-1);
`;

export const TabItem = styled(Flex)<{ active?: boolean }>`
	align-items: center;
	padding: 16px 0;
	cursor: pointer;
	position: relative;
	gap: 8px;
	color: ${(props) =>
		props.active
			? "var(--mb-color-light-blue-8)"
			: "var(--mb-color-light-gray-8)"};
	font-size: 14px;
	font-weight: 500;

	&::after {
		content: "";
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		height: 2px;
		background: ${(props) =>
			props.active ? "var(--mb-color-light-blue-8)" : "transparent"};
		transition: background-color 0.2s ease;
	}

	&:hover {
		color: var(--mb-color-light-blue-8);
	}
`;

export const LogoWrapper = styled(Flex)`
	width: 20px;
	height: 20px;
	align-items: center;
	justify-content: center;

	svg {
		width: 100%;
		height: 100%;
	}
`;

export const AppContent = styled(Flex)`
	padding: 24px;
	flex-direction: column;
	gap: 24px;
`;

export const AutomationToggle = styled(Flex)`
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
	background: var(--mb-color-light-blue-1);
	border-radius: 8px;
	width: 100%;
	max-width: 600px;
`;

export const ToggleText = styled.div`
	color: var(--mb-color-light-gray-11);
	font-size: 14px;
	font-weight: 400;
`;

export const ToggleButton = styled.div<{ $active?: boolean }>`
	width: 44px;
	height: 24px;
	background: ${(props) =>
		props.$active
			? "var(--mb-color-light-blue-8)"
			: "var(--mb-color-light-gray-5)"};
	border-radius: 12px;
	padding: 2px;
	cursor: pointer;
	transition: background-color 0.2s ease;
`;

export const ToggleButtonInner = styled.div<{ $active?: boolean }>`
	width: 20px;
	height: 20px;
	background: var(--mb-color-light-gray-1);
	border-radius: 50%;
	transform: translateX(${(props) => (props.$active ? "20px" : "0")});
	transition: transform 0.2s ease;
`;

export const EmailSection = styled(Flex)`
	flex-direction: column;
	gap: 8px;
	width: 100%;
	max-width: 600px;
`;

export const EmailLabel = styled.div`
	color: var(--mb-color-light-gray-11);
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 4px;
`;

export const EmailInputWrapper = styled(Flex)`
	gap: 12px;
	align-items: flex-start;
`;

export const EmailInputContainer = styled.div`
	flex: 1;
	position: relative;
`;

export const EmailInput = styled.input`
	width: 100%;
	height: 40px;
	padding: 0 12px;
	border: 1px solid var(--mb-color-light-gray-5);
	border-radius: 4px;
	font-size: 14px;
	color: var(--mb-color-light-gray-11);
	outline: none;
	transition: border-color 0.2s ease;

	&:focus {
		border-color: var(--mb-color-light-blue-8);
	}

	&::placeholder {
		color: var(--mb-color-light-gray-7);
	}
`;

export const ValidateButton = styled.button`
	height: 40px;
	padding: 0 16px;
	background: var(--mb-color-light-gray-3);
	border: none;
	border-radius: 4px;
	color: var(--mb-color-light-gray-8);
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.2s ease;

	&:hover {
		background: var(--mb-color-light-gray-4);
	}

	&:active {
		background: var(--mb-color-light-gray-5);
	}
`;

export const ErrorMessage = styled.div`
	color: var(--mb-color-light-red-4);
	font-size: 12px;
	margin-top: 4px;
`;

export const AppIcon = styled(Flex)`
	width: 22px;
	height: 22px;
	position: relative;
	border-radius: 1000px;
`;

export const SalesforceLogo = styled(AppIcon)`
	background: #00a1e0;

	&::after {
		content: "";
		width: 15.31px;
		height: 10.59px;
		background: var(--mb-color-light-gray-1);
		position: absolute;
	}
`;

export const HubspotLogo = styled(AppIcon)`
	background: #ff7a59;

	&::after {
		content: "";
		width: 12.69px;
		height: 13.54px;
		background: var(--mb-color-light-gray-1);
		position: absolute;
	}
`;

export const SlackLogo = styled(AppIcon)`
	background: #4a154b;
	padding: 4.23px;
`;

export const SlackLogoInner = styled.div`
	width: 13.54px;
	height: 13.54px;
	display: grid;
	grid-template-columns: repeat(2, 6.34px);
	grid-template-rows: repeat(2, 6.34px);
	gap: 0.86px;

	> div:nth-child(1) {
		background: #35c5f0;
	}
	> div:nth-child(2) {
		background: #2eb57d;
	}
	> div:nth-child(3) {
		background: #de1c59;
	}
	> div:nth-child(4) {
		background: #ebb02e;
	}
`;

export const GoogleWorkspaceLogo = styled(AppIcon)`
	background: var(--mb-color-light-gray-1);
	border: 1px solid var(--mb-color-light-gray-5);
	padding: 3.3px;
`;

export const ZoomLogo = styled(AppIcon)`
	background: #0e5efe;

	&::after {
		content: "";
		width: 12.69px;
		height: 13.54px;
		background: var(--mb-color-light-gray-1);
		position: absolute;
	}
`;
