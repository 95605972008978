import styled, { css } from "styled-components";
import { Flex, Button, Text } from "@mightybot/web-ui";
import Joyride from "react-joyride";

export const Tour = styled(Joyride)`
	width: 310px;
	padding: 0px;
`;

export const TourPopupWrapper = styled(Flex)`
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
	font-family: var(--default-font-family);
`;

export const TourContainer = styled(Flex)`
	width: 310px;
	padding: 20px;
	background: var(--mb-gray-1);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	border: 2px solid var(--mb-blue-5);
	flex-direction: column;
	gap: 16px;
`;

export const PreviewImage = styled.img`
	height: 171px;
	border-radius: 5px;
	border: 1px solid var(--mb-gray-5);
	object-fit: cover;
`;

export const TourActions = styled(Flex)`
	padding-top: 3px;
	width: 100%;
`;

export const SkipButton = styled(Button)`
	height: 28px;
	padding: 0 10px;
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.4);
	font-size: 14px;
	line-height: 18px;
	background: none;
	border: none;
	cursor: pointer;
	border-radius: var(--radius-pill);
	transition: background 0.2s ease-in-out;

	&:hover {
		background: rgba(0, 0, 0, 0.05);
	}
`;

export const StartButton = styled(Button)`
	height: 34px;
	padding: 0 14px;
	background: var(--mb-blue-8);
	border-radius: 999px;
	color: var(--mb-gray-1);
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	display: flex;
	align-items: center;
	gap: 6px;
	cursor: pointer;
	border: none;
	width: 100%;

	&:hover {
		background: var(--mb-blue-9);
	}
`;

export const TooltipContainer = styled(Flex)<{ placement?: string }>`
	width: 310px;
	padding: 12px;
	background: var(--mb-gray-1);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0px;
	position: relative; /* Enable positioning for the arrow */
	font-family: fk_grotesk_neue;

	/* Arrow for Top Placement */
	${({ placement }) =>
		placement === "top" &&
		css`
			&:after {
				content: "";
				position: absolute;
				bottom: -8px;
				left: 50%;
				transform: translateX(-50%);
				border-width: 8px 8px 0;
				border-style: solid;
				border-color: var(--mb-gray-1) transparent transparent transparent;
			}
		`}

	/* Arrow for Bottom Placement */
	${({ placement }) =>
		placement === "bottom" &&
		css`
			&:after {
				content: "";
				position: absolute;
				top: -8px;
				left: 50%;
				transform: translateX(-50%);
				border-width: 0 8px 8px 8px;
				border-style: solid;
				border-color: transparent transparent var(--mb-gray-1) transparent;
			}
		`}

	/* Arrow for Left Placement */
	${({ placement }) =>
		placement === "right" &&
		css`
			&:after {
				content: "";
				position: absolute;
				left: -8px;
				top: calc(50% - 6px);
				border-width: 8px 8px 8px 0;
				border-style: solid;
				border-color: transparent var(--mb-gray-1) transparent transparent;
			}
		`}

	/* Arrow for Right Placement */
	${({ placement }) =>
		placement === "left" &&
		css`
			&:after {
				content: "";
				position: absolute;
				right: -8px;
				top: calc(50% - 6px);
				border-width: 8px 0 8px 8px;
				border-style: solid;
				border-color: transparent transparent transparent var(--mb-gray-1);
			}
		`}
`;
export const ContentWrapper = styled(Flex)`
	align-self: stretch;
	height: auto;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
`;

export const Title = styled(Text)`
	align-self: stretch;
	color: var(--mb-gray-11);
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
`;

export const Description = styled(Text)`
	align-self: stretch;
	color: var(--mb-gray-11);
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
`;

export const FooterContainer = styled(Flex)`
	align-self: stretch;
	justify-content: space-between;
	align-items: center;
`;

export const StepIndicator = styled(Text)`
	color: var(--mb-gray-7);
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
`;

export const ButtonGroup = styled(Flex)`
	gap: 10px;
	align-items: center;
`;

export const NextButton = styled(Button)`
	height: 28px;
	padding: 0 10px;
	background: var(--mb-blue-8);
	border-radius: 4px;
	color: var(--mb-gray-1);
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	border: none;

	&:hover {
		background: var(--mb-blue-9);
	}
`;
