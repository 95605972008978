import React, { useMemo, useEffect, useState, useRef } from "react";
import { MessagesContainer } from "./styled";
import { useParams } from "react-router-dom";
import {
	useCompletionsHistory,
	useMe,
	useModelSelection,
} from "@mightybot/core";
import Chat from "../Chat/Chat";
import { useStreaming } from "../../contexts/StreamingContext";

type ChatsMessagesProps = {
	temporaryMessage: string | null;
};

const ChatsMessages: React.FC<ChatsMessagesProps> = ({ temporaryMessage }) => {
	const { data: author } = useMe();
	const { chatId: activeConversationId } = useParams();
	const latestMessageRef = useRef<HTMLDivElement>(null);
	const { completionHistory: chats, refetch } = useCompletionsHistory(
		activeConversationId ?? "",
		"CONVERSATION",
	);
	const {
		streamingMessage,
		isStreaming,
		currentStreamingQuestion,
		startStreaming,
		endStreaming,
	} = useStreaming();
	const { getSelectedModel } = useModelSelection();

	// Scroll to latest message when streaming starts or new message is added
	useEffect(() => {
		if (latestMessageRef.current) {
			latestMessageRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [currentStreamingQuestion, chats.length]);

	useEffect(() => {
		if (chats.length > 0) {
			endStreaming();
			window.requestAnimationFrame(() => {
				const container = document.getElementById("chats-content-container");
				if (container) {
					const scrollHeight = container.scrollHeight;
					const height = container.clientHeight;
					const maxScrollTop = scrollHeight - height;
					container.scrollTo({
						top: maxScrollTop > 0 ? maxScrollTop : 0,
						behavior: "smooth",
					});
				}
			});
		}
	}, [chats, activeConversationId]);

	useEffect(() => {
		if (!isStreaming && activeConversationId && chats.length > 0) {
			refetch();
		}
	}, [isStreaming, activeConversationId, chats.length, refetch]);

	const handleRetry = async (
		completionId: string,
		chat: any,
		retryMessage?: string,
		retryContext?: any,
	) => {
		if (isStreaming) return;

		const selectedModel = getSelectedModel();

		try {
			await startStreaming({
				context: retryContext ?? chat.context,
				user_question: retryMessage ?? chat.user_question,
				llm_model: selectedModel.id,
				collection_type: "CONVERSATION",
				collection_id: activeConversationId ?? "",
				completion_id: completionId,
			});
		} catch (error) {
			console.error("Error retrying message:", error);
		}
	};

	const messages = useMemo(() => {
		if (!chats) return [];
		const lastMessage = chats[chats.length - 1];
		const isStreamingMessageInHistory =
			lastMessage &&
			lastMessage.user_question === currentStreamingQuestion &&
			lastMessage.response === streamingMessage;

		const regularChats = chats.map((chat, index) => (
			<Chat
				key={chat.created_at}
				timestamp={chat.created_at}
				question={chat.user_question}
				reply={chat.response || ""}
				author={author}
				completionId={chat.id}
				onRetry={() => handleRetry(chat.id ?? "", chat)}
				streamingFailed={
					!chat.response && chat.user_question !== currentStreamingQuestion
				}
				ref={index === chats.length - 1 ? latestMessageRef : undefined}
			/>
		));

		if (!isStreamingMessageInHistory && currentStreamingQuestion) {
			return [
				...regularChats,
				<Chat
					key="streaming-message"
					timestamp={new Date().toISOString()}
					question={currentStreamingQuestion}
					reply={streamingMessage || ""}
					onRetry={() =>
						handleRetry(
							lastMessage?.id ?? "",
							lastMessage,
							currentStreamingQuestion,
							lastMessage?.context,
						)
					}
					author={author}
					isStreaming={isStreaming}
					ref={latestMessageRef}
				/>,
			];
		}

		return regularChats;
	}, [
		chats,
		isStreaming,
		currentStreamingQuestion,
		streamingMessage,
		author,
		temporaryMessage,
		activeConversationId,
	]);

	return (
		<MessagesContainer $newchat={!activeConversationId ? "true" : "false"}>
			{messages}
		</MessagesContainer>
	);
};

export default ChatsMessages;
