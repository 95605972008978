import React from "react";
import Layout from "../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import MeetingPreview from "../components/MeetingPreview";
import MeetingDetails from "../components/MeetingDetails";

export default function MeetingsDetailRoute() {
	let { view, meetingId } = useParams();
	const navigate = useNavigate();

	if (!view && meetingId) {
		navigate(`/meetings/${meetingId}/preview`);
	}

	return (
		<Layout>
			{view === "details" ? <MeetingDetails /> : <MeetingPreview />}
		</Layout>
	);
}
