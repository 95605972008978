import React, { useState, useEffect } from "react";
import {
	SlackLogo,
	SalesforceLogo,
	HubspotLogo,
	GoogleWorkspaceLogo,
	ZoomLogo,
} from "@mightybot/core-ui";
import {
	useMe,
	useEnterprisePreferences,
	useSaveEnterprisePreferences,
	AppName,
	EnterpriseAppConfig,
} from "@mightybot/core";
import {
	Container,
	Header,
	HeaderTitle,
	Content,
	NavTabs,
	TabItem,
	AutomationToggle,
	ToggleButton,
	ToggleButtonInner,
	EmailSection,
	EmailLabel,
	EmailInputWrapper,
	EmailInput,
	EmailInputContainer,
	ValidateButton,
	LogoWrapper,
	AppContent,
	ToggleText,
	ErrorMessage,
	AppIcon,
} from "./styled";
import { isValidEmail } from "../../../utils/helpers";

interface AppState {
	adminEmail: string;
	automationEnabled: boolean;
	error?: string;
}

interface AppConfig {
	id: AppName;
	name: string;
	logo: string;
	content: {
		toggleText: string;
		emailLabel: string;
		emailPlaceholder: string;
	};
}

type ToastData = {
	title: string;
	description?: string;
	status: "success" | "error" | "info";
};

const APPS: AppConfig[] = [
	{
		id: "SALESFORCE" as AppName,
		name: "Salesforce",
		logo: SalesforceLogo,
		content: {
			toggleText: "Run Salesforce Automations as Admin User",
			emailLabel:
				"Tell us who is the Salesforce admin to perform all the automations",
			emailPlaceholder: "Enter Salesforce admin email",
		},
	},
	{
		id: "HUBSPOT" as AppName,
		name: "Hubspot",
		logo: HubspotLogo,
		content: {
			toggleText: "Run Hubspot Automations as Admin User",
			emailLabel:
				"Tell us who is the Hubspot admin to perform all the automations",
			emailPlaceholder: "Enter Hubspot admin email",
		},
	},
	{
		id: "SLACK" as AppName,
		name: "Slack",
		logo: SlackLogo,
		content: {
			toggleText: "Run Slack Automations as Admin User",
			emailLabel:
				"Tell us who is the Slack admin to perform all the automations",
			emailPlaceholder: "Enter Slack admin email",
		},
	},
	{
		id: "GOOGLE" as AppName,
		name: "Google Workspace",
		logo: GoogleWorkspaceLogo,
		content: {
			toggleText: "Run Google Workspace Automations as Admin User",
			emailLabel:
				"Tell us who is the Google Workspace admin to perform all the automations",
			emailPlaceholder: "Enter Google Workspace admin email",
		},
	},
];

const AppsConfiguration = () => {
	const { data: user } = useMe();
	const { data: enterprisePreferences } = useEnterprisePreferences(
		user?.enterprise_id ?? "",
	);
	const [savePreferences, { isLoading }] = useSaveEnterprisePreferences();
	const [selectedApp, setSelectedApp] = useState<AppName>(
		"SALESFORCE" as AppName,
	);
	const [appStates, setAppStates] = useState<Record<AppName, AppState>>(
		{} as Record<AppName, AppState>,
	);
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState<ToastData>({
		title: "",
		status: "info",
	});

	// Initialize or update app states when preferences change
	useEffect(() => {
		if (enterprisePreferences?.apps_config) {
			const newAppStates = APPS.reduce(
				(acc, app) => ({
					...acc,
					[app.id]: {
						adminEmail:
							enterprisePreferences.apps_config[app.id]?.email_address || "",
						automationEnabled:
							enterprisePreferences.apps_config[app.id]?.is_enabled || false,
						error: undefined,
					},
				}),
				{} as Record<AppName, AppState>,
			);
			setAppStates(newAppStates);
		}
	}, [enterprisePreferences]);

	const handleAppSelect = (appId: AppName) => {
		setSelectedApp(appId);
	};

	const handleToggleAutomation = async (appId: AppName) => {
		if (!user?.enterprise_id || !appStates[appId]) return;

		const newState = !appStates[appId].automationEnabled;

		// If trying to enable automation without an email, show error
		if (newState && !appStates[appId].adminEmail.trim()) {
			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					error:
						"Please enter an admin email address before enabling automation",
				},
			}));
			setToastData({
				title: "Please enter an admin email address before enabling automation",
				status: "error",
			});
			setOpenToast(true);
			return;
		}

		try {
			const currentConfig = enterprisePreferences?.apps_config ?? {};
			const newConfig: Record<AppName, EnterpriseAppConfig> = {
				...currentConfig,
				[appId]: {
					is_enabled: newState,
					email_address: appStates[appId].adminEmail,
				},
			};

			await savePreferences({
				enterprise_id: user.enterprise_id,
				preferences: {
					apps_config: newConfig,
					meeting_recording_preferences:
						enterprisePreferences?.meeting_recording_preferences ?? {
							generate_doc_transcript: true,
							get_meeting_recording_in_drive: true,
						},
				},
			}).unwrap();

			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					automationEnabled: newState,
					error: undefined,
				},
			}));
			setToastData({
				title: "App configuration updated",
				status: "success",
			});
			setOpenToast(true);
		} catch (error) {
			console.error("Failed to update app configuration:", error);
			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					error: "Failed to update configuration",
				},
			}));
			setToastData({
				title: "Failed to update app configuration",
				status: "error",
			});
			setOpenToast(true);
		}
	};

	const handleEmailChange = async (appId: AppName, email: string) => {
		if (!isValidEmail(email) && email.trim() !== "") {
			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					adminEmail: email,
					error: "Please enter a valid email address",
				},
			}));
			return;
		}

		setAppStates((prev) => ({
			...prev,
			[appId]: {
				...prev[appId],
				adminEmail: email,
				error: undefined,
			},
		}));
	};

	const handleValidate = async (appId: AppName) => {
		if (!user?.enterprise_id || !appStates[appId]) return;

		if (!isValidEmail(appStates[appId].adminEmail)) {
			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					error: "Please enter a valid email address",
				},
			}));
			setToastData({
				title: "Please enter a valid email address",
				status: "error",
			});
			setOpenToast(true);
			return;
		}

		try {
			const currentConfig = enterprisePreferences?.apps_config ?? {};
			const newConfig: Record<AppName, EnterpriseAppConfig> = {
				...currentConfig,
				[appId]: {
					is_enabled: appStates[appId].automationEnabled,
					email_address: appStates[appId].adminEmail,
				},
			};

			await savePreferences({
				enterprise_id: user.enterprise_id,
				preferences: {
					apps_config: newConfig,
					meeting_recording_preferences:
						enterprisePreferences?.meeting_recording_preferences ?? {
							generate_doc_transcript: true,
							get_meeting_recording_in_drive: true,
						},
				},
			}).unwrap();

			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					error: undefined,
				},
			}));
			setToastData({
				title: "Configuration saved",
				status: "success",
			});
			setOpenToast(true);
		} catch (error) {
			console.error("Failed to validate app configuration:", error);
			setAppStates((prev) => ({
				...prev,
				[appId]: {
					...prev[appId],
					error: "Failed to validate configuration",
				},
			}));
			setToastData({
				title: "Failed to validate configuration",
				status: "error",
			});
			setOpenToast(true);
		}
	};

	const selectedAppConfig = APPS.find((app) => app.id === selectedApp);
	const selectedAppState = appStates[selectedApp];

	if (!selectedAppConfig || !selectedAppState) return null;

	return (
		<Container>
			<Header>
				<HeaderTitle>Apps Configuration (Admin)</HeaderTitle>
			</Header>

			<Content>
				<NavTabs>
					{APPS.map((app) => (
						<TabItem
							key={app.id}
							active={selectedApp === app.id}
							onClick={() => handleAppSelect(app.id)}
						>
							<LogoWrapper>
								<AppIcon>
									<img src={app.logo} alt={app.name} />
								</AppIcon>
							</LogoWrapper>
							{app.name}
						</TabItem>
					))}
				</NavTabs>

				<AppContent>
					<AutomationToggle>
						<ToggleText>{selectedAppConfig.content.toggleText}</ToggleText>
						<ToggleButton
							$active={selectedAppState.automationEnabled}
							onClick={() => handleToggleAutomation(selectedApp)}
						>
							<ToggleButtonInner $active={selectedAppState.automationEnabled} />
						</ToggleButton>
					</AutomationToggle>

					<EmailSection>
						<EmailLabel>{selectedAppConfig.content.emailLabel}</EmailLabel>
						<EmailInputWrapper>
							<EmailInputContainer>
								<EmailInput
									type="email"
									placeholder={selectedAppConfig.content.emailPlaceholder}
									value={selectedAppState.adminEmail}
									onChange={(e) =>
										handleEmailChange(selectedApp, e.target.value)
									}
								/>
								{selectedAppState.error && (
									<ErrorMessage>{selectedAppState.error}</ErrorMessage>
								)}
							</EmailInputContainer>
							<ValidateButton
								onClick={() => handleValidate(selectedApp)}
								disabled={!selectedAppState.adminEmail.trim() || isLoading}
							>
								Save
							</ValidateButton>
						</EmailInputWrapper>
					</EmailSection>
				</AppContent>
			</Content>
		</Container>
	);
};

export default AppsConfiguration;
