import React, { useState } from "react";
import {
	MeetingPreviewContainer,
	MeetingPreviewContentContainer,
} from "./styled";

import MeetingsPreviewSidebar from "../MeetingsPreviewSidebar";
import MeetingPreviewContent from "../MeetingContent";
import { MeetingContentNavigationProvider } from "../../contexts/MeetingContentNavigationContext";
import { useMeetingsListBidirectional } from "@mightybot/core";
import { PlaceholderMeetingListPage } from "@mightybot/core-ui";
import Placeholder from "../../utils/Placeholder";

const MeetingPreview: React.FC = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const { meetings, isLoading } = useMeetingsListBidirectional({
		initialCursor: new Date().toISOString(),
	});

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	if (!isLoading && (!meetings || meetings.length === 0)) {
		return (
			<MeetingPreviewContentContainer>
				<Placeholder
					placeholder={PlaceholderMeetingListPage}
					imageStyle={{ height: "auto" }}
				/>
			</MeetingPreviewContentContainer>
		);
	}

	return (
		<MeetingContentNavigationProvider>
			<MeetingPreviewContainer>
				<MeetingsPreviewSidebar
					isSidebarOpen={isSidebarOpen}
					toggleSidebar={toggleSidebar}
				/>
				<MeetingPreviewContent />
			</MeetingPreviewContainer>
		</MeetingContentNavigationProvider>
	);
};

export default MeetingPreview;
