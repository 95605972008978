export function initializeServiceWorker() {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker
			.register("./service-worker.ts")
			.then((registration) => {
				console.log("Service Worker registered:", registration);
			})
			.catch((error) => {
				console.error("Service Worker registration failed:", error);
			});
	}
}
