import React, { useState, useEffect } from "react";
import Joyride, {
	CallBackProps,
	STATUS,
	TooltipRenderProps,
} from "react-joyride";
import { useLocation, useNavigate } from "react-router-dom";
import { useMe, useTour } from "@mightybot/core";
import { Flex, Icon, Text } from "@mightybot/web-ui"; // Added missing import
import { tourSteps } from "./tourSteps";
import TourPopup from "./TourPopup";
import { ButtonGroup, NextButton, SkipButton, Title } from "./styled";
import {
	StepIndicator,
	FooterContainer,
	Description,
	TooltipContainer,
} from "./styled";
import { ContentWrapper } from "./styled";
const CustomTooltip = ({
	continuous,
	index,
	step,
	isLastStep,
	skipProps,
	primaryProps,
	tooltipProps,
}: TooltipRenderProps) => (
	<TooltipContainer {...tooltipProps} placement={step.placement}>
		<ContentWrapper>
			<Flex
				style={{
					alignSelf: "stretch",
					justifyContent: "space-between",
					alignItems: "flex-start",
				}}
			>
				<Title>{step.title}</Title>
				<SkipButton
					{...skipProps}
					style={{ padding: "4px", height: "auto", minWidth: "auto" }}
				>
					<Icon.X size={16} color="var(--mb-color-dark-gray-4)" />
				</SkipButton>
			</Flex>
			<Description>{step.content}</Description>
			<FooterContainer>
				<StepIndicator>{`${index + 1} of ${tourSteps.length}`}</StepIndicator>
				<ButtonGroup>
					<SkipButton {...skipProps}>Skip</SkipButton>
					<NextButton {...primaryProps}>
						{isLastStep ? "Finish" : "Next"}
					</NextButton>
				</ButtonGroup>
			</FooterContainer>
		</ContentWrapper>
	</TooltipContainer>
);
const Tour = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { data: user } = useMe();
	const { tourStatus, completeTour, remindLater, shouldShowTour } = useTour();
	const [run, setRun] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [stepIndex, setStepIndex] = useState(0);
	const [isNavigating, setIsNavigating] = useState(false);

	useEffect(() => {
		const isHomePage = location.pathname === "/home";
		if (isHomePage && user && shouldShowTour) {
			setShowPopup(true);
		} else {
			setShowPopup(false);
		}
	}, [location.pathname, user, shouldShowTour]);

	useEffect(() => {
		if (user && !isNavigating && !showPopup && run) {
			setRun(true);
		}
	}, [user, isNavigating, showPopup, run]);
	const handleJoyrideCallback = async (data: CallBackProps) => {
		const { status, type, index, action } = data;
		console.log("handleJoyrideCallback", data);

		if (status === STATUS.SKIPPED) {
			await remindLater();
			setRun(false);
			setStepIndex(0);
			setShowPopup(false);
			return;
		}

		if (type === "step:after") {
			const nextIndex = index + 1;
			if (nextIndex < tourSteps.length) {
				setIsNavigating(true);
				setRun(false);
				const nextStep = tourSteps[nextIndex];
				navigate(nextStep.path);

				setTimeout(() => {
					setStepIndex(nextIndex);
					setIsNavigating(false);
					setRun(true);
				}, 300); // Increased timeout for better transition
			}
		}
		if (index === tourSteps.length - 1 && action === "next") {
			await completeTour();
			setRun(false);
			setStepIndex(0);
			setShowPopup(false);
			return;
		}
	};

	const handleStartTour = () => {
		setShowPopup(false);
		setRun(true);
	};

	const handleSkipTour = () => {
		setShowPopup(false);
	};

	if (showPopup) {
		return (
			<TourPopup
				isOpen={showPopup}
				onClose={handleSkipTour}
				onStartTour={handleStartTour}
			/>
		);
	}

	if (!run) {
		return null;
	}

	return (
		<Joyride
			callback={handleJoyrideCallback}
			continuous
			hideCloseButton
			run={run}
			scrollToFirstStep
			showProgress
			showSkipButton
			stepIndex={stepIndex}
			steps={tourSteps}
			tooltipComponent={CustomTooltip}
			floaterProps={{
				styles: {
					floaterOpening: {
						padding: "0px",
					},
				},
			}}
			styles={{
				options: {
					arrowColor: "transparent",
					backgroundColor: "var(--mb-gray-1)",
					primaryColor: "var(--mb-color-dark-blue-8)",
					zIndex: 10000,
					width: "auto",
				},
				tooltip: {
					transform: "none",
					marginTop: "-16px",
				},
				tooltipContainer: {
					textAlign: "left",
					transform: "none",
				},
			}}
		/>
	);
};

export default Tour;
