import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import {
	Flex,
	Button,
	Text,
	Icon,
	Spinner,
	LazyIcon,
	Skeleton,
} from "@mightybot/web-ui";

import * as Styled from "./styled";
import {
	GoogleAppType,
	UPDATE_INTEGRATIONS,
	useIntegrationConnect,
	useIntegrations,
} from "@mightybot/core";
import {
	MBLogoHeader,
	useIntegrationLogos,
	OnboardingAnimationFallback,
} from "@mightybot/core-ui";
import OnboardingAnimation from "./OnboardingAnimation.json";
import WhatYoullGet from "./WhatYoullGet.json";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";

const requiredIntegrations = [
	"gmail",
	"google_calendar",
	"google_drive",
	"google_docs",
	"google_sheets",
];

const Onboarding = () => {
	const [isConnecting, setIsConnecting] = useState(false);
	const { getIntegrationLogo } = useIntegrationLogos();
	const navigate = useNavigate();
	useEffect(() => {
		const listener = (event: MessageEvent) => {
			if (event.data.type === UPDATE_INTEGRATIONS) {
				setIsConnecting(false);
				const navigateTo = "/home";
				navigate(navigateTo);
			}
		};
		window.addEventListener("message", listener);
		return () => window.removeEventListener("message", listener);
	}, []);
	const { data: integrations } = useIntegrations();
	const requiredIntegrationsData = integrations?.filter((integration) =>
		requiredIntegrations.includes(integration.id),
	);
	const { connectGoogleAppBatch } = useIntegrationConnect();

	const handleConnect = async () => {
		if (requiredIntegrationsData) {
			setIsConnecting(true);
			await connectGoogleAppBatch(requiredIntegrationsData as GoogleAppType[]);
		}
	};

	return (
		<Styled.Container>
			<Styled.OnboardingWrapper
				justify="center"
				align="start"
				direction="column"
			>
				<Styled.LogoWrapper>
					<img
						src={MBLogoHeader as string}
						alt="logo"
						style={{ maxWidth: "100%", height: "100%" }}
					/>
				</Styled.LogoWrapper>
				<Styled.ContentBox direction="column" align="center">
					<Styled.ContentHeader direction="column" align="center">
						<Styled.OverlappingIcons>
							{!requiredIntegrationsData && (
								<>
									{[...Array(5)].map((_, index) => (
										<Flex key={index} justify="center" align="center">
											<Skeleton
												key={index}
												width="50px"
												height="50px"
												style={{
													marginLeft: index > 0 ? "-10px" : "0",
													zIndex: 5 - index,
													borderRadius: "50%",
												}}
											/>
										</Flex>
									))}
								</>
							)}
							{requiredIntegrationsData &&
								requiredIntegrations?.map((integration, index) => (
									<Flex
										justify="center"
										align="center"
										key={integration}
										style={{
											marginLeft: index > 0 ? "-10px" : "0",
											zIndex: requiredIntegrations.length - index,
											width: "50px",
											height: "50px",
										}}
									>
										<LazyIcon
											src={getIntegrationLogo(integration as string) as string}
											alt={integration as string}
										/>
									</Flex>
								))}
						</Styled.OverlappingIcons>
						<Text weight="bold" size="4">
							Connect Google Workspace to start your automations
						</Text>
					</Styled.ContentHeader>
					<Styled.ContentBody direction="column">
						<Styled.Content direction="column" align="start">
							<Text style={{ marginBottom: "5px" }} weight="bold">
								What you’ll get
							</Text>
							{WhatYoullGet.map((item) => (
								<Flex
									key={item as string}
									direction="row"
									align="start"
									style={{ gap: "10px" }}
								>
									<Icon.Check
										size="18px"
										style={{ color: "var(--mb-blue-8)", flexShrink: 0 }}
									/>
									<Text
										weight="light"
										style={{ color: "var(--mb-color-dark-gray-4)" }}
									>
										{item as string}
									</Text>
								</Flex>
							))}
						</Styled.Content>
						{/* @ts-ignore */}
						<Styled.ConnectButton onClick={handleConnect}>
							{isConnecting ? <Spinner weight="fill" size={22} /> : "Connect"}
						</Styled.ConnectButton>
					</Styled.ContentBody>
				</Styled.ContentBox>
				<Styled.Footer align="center" justify="between">
					<Text weight="light" style={{ color: "var(--mb-color-dark-gray-4)" }}>
						<Text weight="bold">Having trouble?</Text> Feel free to contact us.
					</Text>
					{/* @ts-ignore */}
					<Button
						style={{ cursor: "pointer" }}
						variant="soft"
						onClick={() => {
							window.open(
								"https://www.mightybot.ai/help/contact-support",
								"_blank",
							);
						}}
					>
						Contact Us
					</Button>
				</Styled.Footer>
			</Styled.OnboardingWrapper>
			<Styled.ImageContainer align="end" justify="end">
				<ErrorBoundary
					fallback={
						<img
							src={OnboardingAnimationFallback as string}
							alt="Onboarding Animation Fallback"
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
						/>
					}
				>
					<Lottie
						animationData={OnboardingAnimation as any}
						key="animation-onboarding"
						loop={true}
						play={true}
						style={{ width: "100%", height: "100%", display: "block" }}
						rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
					/>
				</ErrorBoundary>
			</Styled.ImageContainer>
		</Styled.Container>
	);
};

export default Onboarding;
